import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { Header } from './components/common';
import { Footer } from './components/common';
import Home from './components/common/Pages/Home';
import Faq from './components/common/Pages/Faq';
import Privacy from './components/common/Pages/Privacy';
import Terms from './components/common/Pages/Terms';
import Guide from './components/common/Pages/Guide';
import Know from './components/common/Pages/Know';
import PrivacyEn  from './components/common/Pages/PrivacyEn';
import FaqEn from './components/common/Pages/FaqEn';
import GuideEn from './components/common/Pages/GuideEn';
import KnowEn from './components/common/Pages/KnowEn';
import Termseng from './components/common/Pages/Termseng';

const App = () => {
  return (
    <Router>
     <div className="App">
      {((window.location.pathname !== '/content/quick-guide/si') && (window.location.pathname !== '/content/quick-guide/en') && (window.location.pathname !== '/content/quick-guide/know/si') && (window.location.pathname !== '/content/quick-guide/know/en')) && <Header /> }
      <Switch>
      <Route path='/' component={Home} exact />
      {/* <Route path='/content/privacy-policy/:lang' component={ppRoute} exact /> */}
      <Route path='/content/privacy-policy/si' component={Privacy} exact />
      <Route path='/content/terms-of-service/si' component={Terms} exact />
      <Route path='/content/faq/si' component={Faq} exact />
      <Route path='/content/quick-guide/si' component={Guide} exact />
      <Route path='/content/quick-guide/know/si' component={Know} exact />
      <Route path='/content/terms-of-service/en' component={Termseng} exact />
      <Route path='/content/privacy-policy/en' component={PrivacyEn} exact />
      <Route path='/content/faq/en' component={FaqEn} exact />
      <Route path='/content/quick-guide/en' component={GuideEn} exact />
      <Route path='/content/quick-guide/know/en' component={KnowEn} exact />


      <Redirect to='/' ></Redirect>
      </Switch>
      {((window.location.pathname !== '/content/quick-guide/si') && (window.location.pathname !== '/content/quick-guide/en') && (window.location.pathname !== '/content/quick-guide/know/si') && (window.location.pathname !== '/content/quick-guide/know/en')) &&  <Footer />}
      
      </div>
    </Router>
  );
}

export default App;

