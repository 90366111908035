import React from "react";
import "./footer.css";
import image from "./img/f-logo.png";
import apple from "./img/AppStore.png";
import android from "./img/GooglePlay.png";

const Footer = () => {
  return (
    <section className="sec-footer">
      <div className="fo-con">
        <img className="img-logo" src={image} alt="log-f" />
        <div className="copy-r">
          <p>&copy; 2021 ViziDrive</p>
        </div>
        <div className="link">
          <a
            href="https://app.vizidrive.eu/content/privacy-policy/si"
            target="_blank"
            rel="noopener noreferrer"
          >
            Politika zasebnosti
          </a>
          <br />
          <a
            href="https://app.vizidrive.eu/content/terms-of-service/si"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spološni pogoji uporabe
          </a>
          <br />
          <a
            href="https://www.kranj.si/files/javni-razpisi-in-narocila/2022/javni-razpisi/objava-sklepov-38-seja-19-10-2022.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cenik
          </a>
        </div>
        <div className="apple-img">
          <a href="https://apps.apple.com/si/app/vizidrive/id1603119856">
            <img src={apple} width={120} alt="logo"></img>{" "}
          </a>
        </div>
        <div className="android-img">
          <a href="https://play.google.com/store/apps/details?id=eu.vizidrive.app">
            <img src={android} width={120} alt="logo" />
          </a>
        </div>
        <div className="contact">
          <p>
            Podpora uporabnikom: 24/7: <br />{" "}
            <a href="tel:(+386)51-754-407">Tel: +386 51 754 407 </a>
            <br />
            <a href="mailto:info@vizidrive.eu">info@vizidrive.eu</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
