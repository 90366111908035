import React from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './Faq.css';


const Faq = () => {
    return (
        <Container fluid="md">
        <Col>
        <Row className="justify-content-md-center">
        <Card body className="body">
            <Card.Header>
                 <Card.Title>Splošno</Card.Title>
                </Card.Header>
                <br/>
            <Card.Text>
                <h5>1.	Ali je storitev polnjenja plačljiva?</h5>
                <p>Storitev polnjenja je trenutno za vse registrirane uporabnike na polnilni infrastruktri v upravljanju podjetja Vizije mobilnosti d.o.o. brezplačna. Za uporabo storitev veljajo splošni pogoji uporabe in politika varovanja zasebnosti.</p> 
                <h5>2.	Katera plačilna sredstva so podprta?</h5>
                <p>Storitev polnjenja je trenutno za vse registrirane uporabnike na polnilni infrastruktri v upravljanju podjejta Vizije mobilnosti d.o.o. brezplačna.</p>
                <h5>3.	Ali lahko uporabljam mobilno aplikacijo ViziDrive brez registracije?</h5>
                <p>V skladu s trenutno veljavnimi splošnimi pogoji uporabe, je osnovna funkcionalnost mobilne aplikacije ViziDrive omogočena brez registracije. Za uporabo naprednih funkcionalnosti in funkcionalnosti, vezanih na uporabniški račun uporabnika, pa je potrebna registracija in prijava uporabnika.</p>
                <h5>4.	Kje lahko dostopam do splošnih pogojev uporabe in politike varovanja zasebnosti?</h5>
                <p>Splošni pogoji uporabe in politika varovanja zasebnosti so objavljeni na spletni strani <a href="www.vizidrive.eu">www.vizidrive.eu</a>.</p>
                </Card.Text>
            
                <br/>
                <Card.Header>
                    <Card.Title>Polnilna infrastruktura</Card.Title>
                </Card.Header>
                <br/>
                <Card.Text>
                    <h5>1.	Kaj storim, če polnilna postaja ne deluje oziroma je poškodovana?</h5>
                    <p>V primeru vidnih poškodb na kateremkoli delu polnilne postaje (ohišje polnilne postaje, priključni konektor ali priključni kabel), uporaba polnilnice ni dovoljena. Za prijavo napake in nadaljnje informacije prosimo kontaktirajte ekipo ViziDrive.</p>
                    <h5>2.	Kaj storim, če je okolica polilne postaje neurejena ali ne ustreza opisu v mobilni aplikaciji?</h5>
                    <p>V primeru neurejenosti okolice polnilne postaje oziroma, če opis lokacije polnilne postaje ne ustreza dejanskemu stanju, vas prosimo, da to sporočite ekipi ViziDrive.</p>
                    <h5>3.	Kaj storim, če imam dodatna vprašanja?</h5>
                    <p>V primeru dodatnih vprašanj ali pojasnil se prosimo obrnite na ekipo ViziDrive.</p>
  
                </Card.Text>
            <br/>
                <Card.Header>
                <Card.Title> Ekipa ViziDrive</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <h5>1.	Kdo je ekipa ViziDrive?</h5>
                        <p>Blagovna znamka ViziDrive je v upravljanju podjetja Vizije mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani, Slovenija.   </p>
                        <p>spletna stran: www.vizije-mobilnosti.si</p>
                        <p>
                              spletna stran: <a href="https://www.vizije-mobilnosti.si">www.vizije-mobilnosti.si</a>
                              <br />
                              email: <a href="mailto:iinfo@vizije-mobilnosti.si">info@vizije-mobilnosti.si</a>
                        </p>
                        <h5>2.	Kako kontaktiram ekipo ViziDrive?</h5>
                        <p>Kontaktni podatki:</p>
                        <p>tel: +386 51 754 407</p>
                        <p>email: <a href="mailto:info@vizidrive.eu">info@vizidrive.eu </a></p>
                        <p>spletna stran: <a href="https://www.vizidrive.eu">www.vizidrive.eu</a></p>
                    </Card.Text>   
            <br/>
                    <Card.Header>
                <Card.Title>Polnjenje vozila</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <h5>1.	Komu je namenjeno polnjenje vozil?</h5>
                        <p>Polnjenje vozil je namenjeno vsem lastnikom oziroma uporabnikom baterijskih električnih vozil (BEV, ang. Battery Eletric Vehicle) in hibridnih električnih vozil (PHEV, ang. Plug-in Hybrid Electric Vehicle).</p>
                        <h5>2.	Zakaj ne morem pričeti s polnjenjem?</h5>
                        <p>Pred pričetkom polnjenja je potrebna registracija uporabnika in prijava v aplikacijo. 
                            Če pričetek polnjenja kljub temu ni mogoč, preverite ali je polnilni kabel pravilno nameščen in imate vključen dostop do mobilnih podatkov na mobilni napravi.
                            V primeru aplikativnih težav s pričetkom polnjenja, aplikacija javi tip in opis napake polnjenja. Za dodatna pojasnila se lahko obrnete na ekipo ViziDrive, in povprašajte po rešitvi glede na tip ali opis napake
                        </p>
                        <h5>3.	Zakaj moje vozilo ne polni z maksimalno močjo?</h5>
                        <p>Moč polnjenja je odvisna od več dejavnikov. Najpogostejši dejavniki za omejitev moči polnjenja so:</p>
                        <p> - tip kabla <br /> <br />
                            Kabel, ki ga uporabljamo za polnjenje vozila ne omogoča polnjenja na razpoložljivi moči polnine postaje,
                            </p>
                        <p> - dinamično uravanavanje moči polnjenja <br /> <br />
                                Polnilna postaja dinamično uravanava moč polnjenja glede na trenutno razpoložljivo električno moč in zasedenost polnilnic na isti lokaciji,
                            </p> 
                        <p> - omejitev moči polnjenja na vozilu <br /> <br />
                            Vozilo je omejilo maksimalno moč polnjenja z namenom varovanja baterije ter ostalih ključnih sestavnih delov vozila. <br />
                            V primeru dodatnih vprašanj ali pojasnil se prosimo obrnite na ekipo ViziDrive
                        </p>

                        <h5>4.	Zakaj ne morem zaključiti polnjenja?</h5>
                        <p>Preverite ali imate vključen dostop do mobilnih podatkov na mobilni napravi. <br />
                            V primeru aplikativnih težav s zaključkom polnjenja, aplikacija javi tip in opis napake polnjenja. Za dodatna pojasnila se lahko obrnete na ekipo ViziDrive, in povprašajte po rešitvi glede na tip ali opis napake.
                            </p>
                        <h5>5.	Kaj storim, če po končanem polnjenju polnilni kabel ostane zaklenjen v polnilni postaji ali vozilu?</h5>
                        <p>Po končanem polnjenju je potrebno najprej kabel izključiti iz vozila šele nato iz polnilne postaje. V kolikor kabla ni mogoče izklopiti iz vozila, preverite ali je polnjenje v aplikaciji zaključeno ter ukrepajte skladno z navodili za uporabo vašega vozila. <br />
                            V primeru dodatnih težav se obrnite na ekipo ViziDrive.
                            </p>
                        <h5>6.	Kaj storim, če imam dodatna vprašanja?</h5>
                        <p>V primeru dodatnih vprašanj ali pojasnil se prosimo obrnite na ekipo ViziDrive.</p>
                    
                    </Card.Text>    
            <br/>
                    <Card.Header>
                <Card.Title>Splošni pogoji uporabe in politika varovanja zasebnosti</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p><a href="https://app.vizidrive.eu/content/terms-of-service">1. Splošni pogoji</a></p>
                        <p><a href="https://app.vizidrive.eu/content/privacy-policy">2. Politika varovanja zasebnosti</a></p>
                    </Card.Text> 
                    <br/>
                    <Card.Header>
                <Card.Title>Uporabniški računi</Card.Title>
                </Card.Header>
            <br/>
            <Card.Text>
                        <h5>1.	Kaj lahko storim v primeru pozabljenega gesla?</h5>
                        <p>Geslo lahko ponastavite preko mobilne aplikacije ViziDrive <br />
                           V primeru dodatnih vprašanj ali pojasnil se prosimo obrnite na ekipo ViziDrive</p>
                        <h5>2.	Kaj storim, če sumim na zlorabo mojega uporabniškega računa?</h5>
                        <p>V primeru suma zlorabe uporabniškega računa takoj ponastavite geslo preko mobilne aplikacije ViziDrive. <br />
                            V primeru dodatnih vprašanj ali pojasnil se prosimo obrnite na ekipo ViziDrive.
                            </p>
                        <h5>3.	Kaj storim, če imam dodatna vprašanja?</h5>
                        <p>V primeru dodatnih vprašanj ali pojasnil se prosimo obrnite na ekipo ViziDrive. <br />   
                            Ne najdem odgovora na moje vprašanje
                        </p>
                        <h5>1.	Kontaktni podatki ekipe ViziDrive</h5>
                        <p>tel: +386 51 754 407 <br />
                           email:<a href="mailto:info@vizidrive.eu"> info@vizidrive.eu</a> <br />
                           spletna stran:< a href="https://www.vizidrive.eu"> www.vizidrive.eu</a>
                        </p>
                        
                    
                    
                    
                    
                    
                    
                    </Card.Text> 

    </Card>
  </Row>
  </Col>
  <br/>
</Container>
 
    )
}

export default Faq;



