import React from 'react';
import './Terms.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';



const Termseng = () => {
    return (
        <Container fluid="md">
        <Col>
        <Row className="justify-content-md-center">
        <Card body>
            <Card.Header>
                 <Card.Title> GENERAL TERMS AND CONDITIONS OF USING THE VizDrive MOBILE APPLICATION</Card.Title>
                </Card.Header>
                <br/>
                <div className='Title-Terms'>
                    <Card.Header>
                <Card.Title >1. INTRODUCTORY PROVISIONS</Card.Title>
                </Card.Header>
                </div>
        <br/>
            <Card.Text>
                <p>With the general conditions of use of the mobile application ViziDrive (hereinafter: general conditions) Vizije mobilnosti d.o.o. determine the obligations, rights and conditions for the use of the ViziDrive application.</p> 
                <p>These general terms and conditions apply to all upgrades and / or new versions and / or any other changes to the ViziDrive application.</p>
                </Card.Text>
                <br/>
                <Card.Header>
                    <Card.Title>2. DEFINITIONS</Card.Title>
                </Card.Header>
                <br/>
 <Card.Text>
        <div className="first-1">  
<p className="p-1"> - Vizije Mobilnosti is the company Vizije Mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica pri Ljubljani, registration number: 8561796000 and / or its parent, subsidiary or other affiliated companies,</p>
<p className="p-1"> - ViziDrive mobile application is a mobile application that a user installs on their smartphone or tablet via the online mobile store.</p>
<p className="p-1"> - A smartphone or tablet is a device that allows you to install and run advanced applications that are not supported by regular phones, especially more advanced Internet access, multitasking, connectivity, and advanced features.</p>
<p className="p-1"> - Online mobile store is a digital way of distributing applications intended to provide or. installing software applications for users and downloading mobile applications to smartphones</p>
<p className="p-1"> - Login (user) is any entry into the ViziDrive application with the username and password specified in the registration process, when all the necessary data have already been entered into the database and the e-mail address used is confirmed by the user; the user can change the password later.</p>
<p className="p-1"> - ViziDrive user is a fully capable natural person (consumer) who registers to use the ViziDrive application.</p>
<p className="p-1"> - The price list is a stand-alone document in physical or electronic form that defines the prices of the charging service on the charging infrastructure and / or the prices related to the provision of this service set by Vision Mobility and / or another charging infrastructure owner and is published on the website. also displayed in the ViziDrive mobile app.</p>
</div>   
<br/>
<p>In the case of using the ViziDrive application, when using the charging infrastructure, especially for starting and performing charging, the user confirms that he is familiar with and agrees with these general terms of use of the ViziDrive mobile application and the rules on using the charging infrastructure and charging service published on <a href = "www.vizidrive.eu"> www.vizidrive.eu.</a></p>
    </Card.Text>
            <br/>
                <Card.Header>
                <Card.Title> 2. CHANGES IN DATA </Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>In the event that the personal data referred to in point 1 changes, the user must notify Visions of Mobility of the change within 30 days of its creation, by e-mail: info@vizidrive.eu, via the mobile application ViziDrive or in writing to: Visions mobilnosti d.o.o., Pot na Polane 22, 1351 Brezovica. </p>
                        <p>Changes to data transmitted via the ViziDrive mobile application will be considered immediately, and changes to data transmitted by regular mail or e-mail will be considered no later than 5 days from the date of receipt of the written notice.</p>
                    </Card.Text>   
            <br/>
                    <Card.Header>
                <Card.Title>3. TECHNICAL REQUIREMENTS and DOWNLOAD OF ViziDrive MOBILE APPLICATION</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>It is recommended to use LTE, 3G or 2.5G network to download and use the ViziDrive mobile application. In 2G, it is possible to download and use the ViziDrive mobile application, but the background data between the mobile application and the back-end system is transferred more slowly, which can affect the responsiveness of the mobile application.</p>
                        <p>The ViziDrive mobile app runs on iOS (Apple) or Android (Google). Due to technical limitations of older versions of operating systems, certain applications are not available, functionality does not work properly or functionality cannot be displayed.</p>
                        <p>Depending on the existing smartphone and the availability, the user can download the ViziDrive mobile application via online mobile stores for mobile applications, always in the manner and under the conditions set for selected online mobile stores: </p>
                        <p className='p-2'> - Apple iOS: iTunes Store, </p>
                        <p className='p-2'> - Google Android: Play Store. </p>
                        <p>The use of the mobile application is free of charge for the user, and the user is obliged to pay for the data transfer charged by the selected network operator through which he connects to the World Wide Web according to his current price list. Data transfer via the mobile network, which is necessary for the operation of the application, may be charged in the foreign mobile network according to tariff items for data transfer in the foreign network in accordance with the valid price list of the mobile operator with which the user has a contract.</p>
                    </Card.Text>    
            <br/>
                    <Card.Header>
                <Card.Title> 4.FUNCTIONALITIES OF ViziDrive MOBILE APPLICATION, REGISTRATION AND / OR LOGIN</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                        <p>The basic functionalities of the ViziDrive mobile application are in particular, but not exclusively:</p>
                        <p className='p-3'> - display and search for chargers using filters,</p>
                        <p className='p-3'> - display information about the selected charging station and the availability of connections,</p>
                        <p className='p-3'> - entering and scanning code to start charging faster.</p>
                        
                        <p>Registration and / or login is not required to use the basic functionalities of the ViziDrive mobile application.</p>
                        <p>The advanced features of the ViziDrive mobile application include, but are not limited to:</p>
                        <p className='p-3'> - filling performance,</p>
                        <p className='p-3'> - adding and editing means of payment,</p>
                        <p className='p-3'> - review of accounts and charging history on the charging infrastructure,</p>
                        <p className='p-3'> - saving application settings and charger search filters.</p>
                        <p>Registration and login are required to use the advanced functionalities of the ViziDrive mobile application and the functionalities linked to the user's user account. Upon registration, the user confirms that he has read the applicable general terms and conditions and privacy policy, agrees with them and accepts them in full.</p>
                        <p>Registration and login can be performed by the user in the manner allowed by the application. The information that is strictly necessary for the implementation of the registration process and communication for business with the user is marked with a message that the field is mandatory. If the user does not want to provide any mandatory information upon registration, he will not be able to successfully complete it and the use of the ViziDrive mobile application will not be possible.</p>
                        <p>The user can cancel his registration at any time by notifying the cancellation of the registration to the e-mail address: <a href="mailto:info@vizidrive.eu"> info@vizidrive.eu </a> or in writing to the address VIZIJE MOBILNOSTI d.o.o., ViziDrive , Pot na Polane 22, 1351 Brezovica near Ljubljana. After receiving the cancellation of registration, the username is deleted from the list of users of the ViziDrive mobile application.</p>
                    </Card.Text> 
                    <br/>
                    <Card.Header>
                <Card.Title>5. LIMITATION OF LIABILITY</Card.Title>
                </Card.Header>
            <br/>
                    <Card.Text>
                    <p>Visions of Mobility strives for the smooth operation of the mobile application ViziDrive, but does not assume responsibility for damage and / or loss of income that would occur to the user due to possible inactivity or. inability to use the ViziDrive mobile application or possible inaccuracy of information, their inability to use, deficiencies or errors in their content.</p>
                    <p>Visions of mobility are not responsible for any errors and damage and / or loss of income that would occur to the user due to the transmission of incorrect data and / or other actions by the user, e.g. when registering or logging in to the ViziDrive application. Visions of mobility are not responsible for any damage and / or loss of income that may occur due to unauthorized use of the user account or password.</p>
                    <p>Independent external services and applications are used to perform some of the functionalities displayed in the ViziDrive mobile application. The use of these services is subject to the terms of use of the external service set by the owner of the service or application, and Visions of Mobility does not assume any responsibility in this regard and does not provide any guarantees.</p>
                    <p>The ViziDrive application uses, but is not limited to, the Google Maps Distance Matrix API, Maps SDK for Android, Maps Java Script API, Places API, Mobile Crash Reporting API, and Apple Maps.</p>
                    <p>Use of these services is subject to Google Inc. Terms of Service. in Apple Inc. and in the part related to the use of these services, are an integral part of these general terms and conditions and are also binding on the users of the ViziDrive mobile application. They are available at the following links:</p>
                    <p><a href="https://www.google.com/intl/en/policies/terms/">https://www.google.com/intl/en/policies/terms/</a>,</p>
                    <p><a href="https://www.google.com/policies/privacy/ ">https://www.google.com/policies/privacy/ </a> in </p> 
                    <p><a href="https://www.apple.com/legal/internet-services/maps/terms-en.html">https://www.apple.com/legal/internet-services/maps/terms-en.html</a>.</p>
                    <p>Visions of mobility have the right to use other similar services; users are obliged to familiarize themselves with the conditions of use of such services.</p>
                    <p>Visions of mobility do not accept responsibility for errors in content and the correctness and accuracy of published data, which may have occurred due to time inconsistencies, input errors or other unforeseeable causes. Mobility Visions is not responsible for the form and content of data obtained through web links that are not owned by Mobility Visions. Users use the published content at their own risk, and must also ensure adequate data protection on their smartphone or tablet.</p>
                    <p>Visions of Mobility reserves the right to change, add or remove content published in the ViziDrive mobile application at any time without prior notice.</p>
                   </Card.Text>
                   <br/>
                    <Card.Header>
                <Card.Title>6. UPGRADES AND NEW VERSIONS OF ViziDrive MOBILE APPLICATION</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>Due to technical or functional upgrades of the ViziDrive mobile application, Visions of Mobility reserves the right to publish a new version of the ViziDrive mobile application. The user will be informed about the new version of the ViziDrive mobile application either via the mobile application itself or through the store from which the mobile application is downloaded, or the application update on the phone may also occur automatically.</p>
                  </Card.Text>
              <br/>
                    <Card.Header>
                <Card.Title>7. PROCESSING OF PERSONAL DATA</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>Mobility visions process personal data in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46 / EC (GDPR) and national rules on the protection of personal data.</p>
                     <p>The types of personal data, the basis and purpose of processing, data changes, user rights and retention periods are set out in the User Privacy Policy, which is available on the website <a href="www.vizidrive.eu"> www.vizidrive.eu </a>.</p>
                  
                  </Card.Text>
              <br/>
              <Card.Header>
                <Card.Title>8. OBLIGATIONS OF THE USER</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>The user of the ViziDrive mobile application undertakes not to use it for illegal or contrary to the general conditions. The ViziDrive mobile application may not be used in a way that could damage, disable, overload or damage the operation of the ViziDrive mobile application or the Vizi Mobility company.</p>
                     <p>In the event that the user detects any unauthorized use of the username or password, he is obliged to immediately notify the Visions of Mobility to the e-mail address <a href="mailto:info@vizidrive.eu"> info@vizidrive.eu </a>. Visions of mobility reserve the right to, in case of unauthorized use and / or any other abuse of access or use the application by users or any other persons and / or in case of suspected misuse of the password, perform all measures and other actions deemed necessary in its sole discretion to protect their rights and interests and / or to protect the rights and interests of customers and / or users and / or to protect the rights and interests of any other person (in particular, but not limited to: preventing access to the ViziDrive application).</p>
                  
                  </Card.Text>
              <br/>
                 <Card.Header>
                 <Card.Title>9. INTELLECTUAL PROPERTY RIGHTS</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>All data, photos and other information on the ViziDrive mobile application are subject to copyright or other industrial protection, as defined by applicable law governing the protection of copyright and industrial property rights. By downloading the ViziDrive mobile application, the user acquires a non-transferable and non-exclusive right to use the mobile application exclusively on his smartphone or tablet, for non-commercial purposes only. Visions of mobility do not transfer other material copyrights or industrial property rights to the user. Visions of mobility using the mobile application do not give the user the right to use trademarks, logos, corporate identity, which is owned or. holding Visions of Mobility. In particular, the user may not make copies of the ViziDrive mobile application, install applications on a server or other platform, rent or sublicense the ViziDrive mobile application and modify or otherwise interfere with the operation of the ViziDrive application.</p> 
                  </Card.Text>

            <br/>
                 <Card.Header>
                 <Card.Title>10. TRANSITIONAL AND FINAL PROVISIONS</Card.Title>
                </Card.Header>
            <br/>
                   <Card.Text>
                     <p>The current General Terms and Conditions are published on the website <a href="https://www.vizidrive.eu"> www.vizidrive.eu </a>, where any changes and amendments to the current General Terms and Conditions, which enter into force on the day of publication, are also published. It is considered that the user was about the change or. supplement, notified on the day of publication of new or amended or supplemented general conditions. If the user does not agree with the amended or supplemented general terms and conditions, he must stop using the ViziDrive mobile application and delete it from his mobile phone and / or tablet. Any disputes will be resolved by Vision Mobility and the user amicably, and if this is not possible, the competent court in Ljubljana shall have jurisdiction to resolve disputes.</p> 
                     <p>These General Terms and Conditions shall enter into force on the day of their publication on the website <a href="https://www.vizidrive.eu"> www.vizidrive.eu</a></p>
                  </Card.Text>
    </Card>
  </Row>
  </Col>
  <br/>
</Container>
    )
}

export default Termseng;
